<template>
    <div class="pa-4">
      <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          :search="search"
          sort-by="do_no"
          :sort-desc="true"
      >
          <template v-slot:top>
          <v-toolbar flat>
              <v-toolbar-title>{{$route.name}}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                
              <v-spacer></v-spacer>
              <v-btn color="" small depressed @click="initialize" class="mr-2">Refresh</v-btn>
              <v-btn color="primary" small depressed @click="createDO" v-if="$store.state.currentPagePriv.create">Create</v-btn>
          </v-toolbar>
          </template>
          <template v-slot:no-data>
              <v-btn color="primary" @click="initialize">
                  Reset
              </v-btn>
          </template>
          <template v-slot:[`item.delivery_date`]="{ item }">
            {{item.delivery_date | moment}}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">              
                <v-icon small @click="editItem(item)" v-bind="attrs" v-on="on" class="mr-2" v-if="$store.state.currentPagePriv.update">
                    mdi-pencil
                </v-icon>
              </template>
              <span>Edit DO</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">              
                <v-icon small @click="printDO(item)" v-bind="attrs" v-on="on" class="mr-2">
                    mdi-printer
                </v-icon>
              </template>
              <span>Print DO</span>
            </v-tooltip>
          </template>            

      </v-data-table>
      <InputDeliveryOrder ref="refInputDO" @refresh="initialize"></InputDeliveryOrder>
      <PrintDO ref="refPrintDO"></PrintDO>
      <v-snackbar top v-model="saved" color="green">Data berhasil tersimpan!</v-snackbar>
      <v-snackbar top v-model="saveError" color="red accent-2">Data gagal tersimpan! Mohon pastikan data yang akan disimpan sudah sesuai.</v-snackbar>
    </div>
</template>

<script>
  import moment from 'moment'
  import InputDeliveryOrder from '../components/InputDeliveryOrder.vue'
  import PrintDO from '../components/PrintDO.vue'
  
  export default
  {
    components: { 
      InputDeliveryOrder,
      PrintDO 
    }, 
    data: () => ({
      headers: [
        { text: 'Tgl Delivery', value: 'delivery_date' },
        { text: 'No. DO', value: 'do_no' },
        { text: 'Code Customer', value: 'customer_code' },
        { text: 'Nama Customer', value: 'customer_name' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      search: '',
      saved: false,
      saveError: false,
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      },
      prodOrder() {
        return this.$store.state.deliveryorder.obj
      }
    },

    watch: {
    },

    created () {
      this.$store.dispatch('ordercategory/getObjs')
      this.$store.dispatch('customer/getObjs')
      this.$store.dispatch('variant/getObjs')
      this.initialize()
    },
    filters: {
        moment: function (date) {
            return moment(date).format('D MMM YYYY');
        },
    },

    methods: {
      async initialize () {
        this.items = (await this.$store.dispatch('getObjs', {apidomain:'deliveryOrders', parameter:''})).data.data
        console.log(this.items)
      },
      printDO(item) {
        console.log(item)
        this.$refs.refPrintDO.openDialog(item.do_no)
      },

      createDO() {
        this.$refs.refInputDO.do_no = null
        this.$refs.refInputDO.openDialog()
      },

      editItem(item) {
        this.$refs.refInputDO.do_no = item.do_no
        this.$refs.refInputDO.openDialog()
      }
    },
  }
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

</style>