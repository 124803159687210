<template>
  <div class="pa-4">
    <v-dialog v-model="dialog" v-if="dialog" scrollable max-width="90%" persistent :transition="false">

      <v-card class="mt-2 py-2">
        <v-card-title>
            <span class="text-h5">{{$route.name}}</span>
            <v-spacer></v-spacer>
        </v-card-title>    
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-form ref="orderform" id="orderform" v-model="valid" lazy-validation>
            <v-row class="mx-5 py-0 compact-form">
              <v-col cols="6">
                <v-layout class="ma-2">
                  <v-flex align-self-center md4>Group</v-flex>
                  <v-flex md8>
                    <v-select :items="groups" item-text="description" v-model="delivery.customer_category" outlined dense hide-details="auto" @change="filterCustomer(delivery.customer_category)" :rules="reqRule"></v-select>
                  </v-flex>
                </v-layout>
                <v-layout class="ma-2">
                  <v-flex align-self-center md4>Customer</v-flex>
                  <v-flex md8>
                    <v-select :items="customers" item-text="customer_name" item-value="code" :value="delivery.customer_code" return-object outlined dense hide-details="auto" @change="setDeliveryAddress" :rules="reqRule" :disabled="!delivery.customer_category"></v-select>
                  </v-flex>
                </v-layout>
                <v-layout class="ma-2">
                  <v-flex align-self-center md4>Delivery Address</v-flex>
                  <v-flex md8>
                    <v-text-field v-model="delivery.delivery_address" outlined dense hide-details="auto" return-object></v-text-field>
                  </v-flex>
                </v-layout>
              </v-col>
              <v-col cols="6">
                <v-layout class="ma-2">
                  <v-flex align-self-center md4>Remarks</v-flex>
                  <v-flex md8>
                    <v-text-field v-model="delivery.remarks" outlined dense hide-details="auto"></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="ma-2">
                  <v-flex align-self-center md4>Tanggal Delivery</v-flex>
                  <v-flex md8>
                    <v-menu v-model="dateDelivMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="delivery.delivery_date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined :rules="reqRule"></v-text-field>
                      </template>
                      <v-date-picker v-model="delivery.delivery_date" @change="setExpiredDate"></v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>
                <v-layout class="ma-2" v-if="plusExpired">
                  <v-flex align-self-center md4>Tanggal Expired</v-flex>
                  <v-flex md8>
                    <v-menu v-model="dateExpMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" disabled>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="delivery.expired_date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined :rules="reqRule"></v-text-field>
                      </template>
                      <v-date-picker v-model="delivery.expired_date"></v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>
            <v-row class="mr-6 compact-form">
              <v-spacer></v-spacer>
              <v-col cols="4" v-if="!do_no">
                <v-file-input outlined dense label="Upload CSV" @change="onFileChange" prepend-icon="mdi-upload" hide-details="auto"></v-file-input>
              </v-col>

            </v-row>

          <v-row class="mb-2 mt-0">
            <v-col class="ml-6">
              <v-card outlined class="ma-3 mt-5" width="900px">
                <v-simple-table fixed-header height="30vh" id="variantTbl">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Volume</th>
                        <th class="text-left">Variant</th>
                        <th class="text-left">Qty Stock</th>
                        <th class="text-left">Qty Delivery</th>
                        <!-- <th class="text-left">Ambil Produk Ekstra</th> -->
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(variant, vidx) in delivery.details" :key="vidx">
                        <td colspan="2" v-if="variant.add">
                          <v-layout>
                          <v-autocomplete :items="variants" item-text="name" item-value="code" return-object outlined dense hide-details class="compact-form" v-model="variant.variant_code" @change="setAddedVariant($event, variant, vidx)">
                            <template slot="selection" slot-scope="data">
                              {{ data.item.name }} - {{ data.item.size }} ml
                            </template>                            
                            <template slot="item" slot-scope="data">
                              {{ data.item.name }} - {{ data.item.size }} ml
                            </template>                            
                          </v-autocomplete>
                          </v-layout>
                        </td>
                        <td v-if="!variant.add">{{variant.size}} ml</td>
                        <td v-if="!variant.add">{{variant.name}}</td>
                        <td>{{variant.qty_stock}}</td>
                        <td>
                          <v-text-field type="number" dense outlined v-model="variant.qty_delivered" hide-details class="compact-form centered-input" style="width:100px" :rules="reqRule" @wheel="$event.target.blur()"></v-text-field>
                        </td>
                        <td>
                          <v-icon v-if="variant.add" color="red" @click="removeVariant(vidx)">mdi-minus-circle-outline</v-icon>
                        </td>
                      </tr>

                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col align-self="end" class="mb-6">
              <v-btn small color="green" class="white--text" @click="addVariant">
                <v-icon small>mdi-plus</v-icon> Add
                </v-btn>
            </v-col>
          </v-row>
        </v-form>
        </v-card-text>   

        <v-divider></v-divider>
        <v-card-actions class="pr-4">
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false">Close</v-btn>
          <v-btn @click="saveDelivery" color="primary">Save</v-btn>
        </v-card-actions> 
      </v-card>
    </v-dialog>
    <v-snackbar top v-model="saved" color="green">Data berhasil tersimpan!</v-snackbar>
    <v-snackbar top v-model="saveError" color="red accent-2">Data gagal tersimpan! Mohon pastikan data yang akan disimpan sudah sesuai.</v-snackbar>
    <v-overlay :value="onProgress" z-index="999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>        
    <v-dialog v-model="dialogWarning" max-width="500px">
      <v-card>
          <v-card-title class="body-1 text-center">Produk yang Anda pilih sudah ada. Tidak boleh ada produk yang sama dalam 1 DO.</v-card-title>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogWarning = false">OK</v-btn>
          <v-spacer></v-spacer>
          </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      reqRule: [v => !!v || 'Harus diisi!'],
      saved: false,
      saveError: false,
      onProgress: false,
      dialog: false,
      selectedCustomer: {},
      delivery: {},
      delivered: {},
      dateDelivMenu: false,
      dateExpMenu: false,
      orderVariants: [],
      valid: true,
      do_no: null,
      dialogWarning: false,
      customers: []
    }
  },
  computed: {
    prodOrder() {
      return this.$store.state.deliveryorder.obj
    },
    groups() {
      return this.$store.state.ordercategory.objs
    },
    variants() {
      return this.$store.state.variant.objs
    },
    plusExpired() {
      if (this.delivery.customer_category){
        const selectedGroup = this.groups.find(el=> el.code==this.delivery.customer_category)
        if (selectedGroup) return selectedGroup.plus_expired
        else return false
      }
      return false
    }
  },
  filters: {
  },
  async created() {
  },
  methods: {
    async openDialog() {
      this.dialog = true
      this.onProgress = true
      if (this.do_no) {
        //edit DO
        this.delivery = await this.$store.dispatch('deliveryorder/getObj', this.do_no)
        this.delivery.details.forEach(el => {
          const variant = this.variants.find(item => item.code === el.variant_code)
          this.$set(el, 'qty_stock', variant.qty_stock)
        })
      }
      else {
        //new DO
        this.delivery = {
          prod_no: this.$route.params.prod_no,
          customer_category: null,
          customer_code: null,
          delivery_date: null,
          expired_date: null,
          delivery_address: null,
          remarks: null,
          request_date: null,
          details: []
        }
      }
      console.log(this.delivery)
      this.onProgress = false
    },
    filterCustomer(cat) {
      console.log(cat)
      const cust = JSON.parse(JSON.stringify(this.$store.state.customer.objs))
      
      this.customers = cust.filter(el => {
        console.log(el.code, cat)
        return cat == el.category
        })
    },
    getQtySisa(code) {
      const qty = this.delivered.find(item=> {return code==item.variant_code})
      return (qty)?qty.qty_total:0
    },
    async saveDelivery() {
      await this.$refs.orderform.validate();
      if (!this.delivery.details.length) {
        this.valid = false
        this.$store.commit('setSnackbar', {saveError: true, errorMessage: "Line tidak boleh kosong!"})        
      }
      if (!this.valid) return;
      this.onProgress = true
      let payload = this.delivery
      this.cleanObj(payload)
      let docs = {}
      if (this.do_no) {
        payload.do_no = this.do_no
        docs = await this.$store.dispatch('deliveryorder/putObj', payload)
      }
      else {
        docs = await this.$store.dispatch('deliveryorder/postObj', payload)
      }
      if (docs.status==200 || docs.status==201){
        this.onProgress = false
        this.saved = true
        this.dialog = false
        this.$emit('refresh')
      }
      else {
        this.onProgress = false
        this.saveError = true
      }
      console.log(docs)
    },
    setExpiredDate() {
      if (this.plusExpired){
        const expdays = (this.groups.find(el=> el.code==this.delivery.customer_category)).plus_expired
        this.delivery.expired_date = moment(this.delivery.delivery_date).add(expdays, 'd').format("YYYY-MM-DD")
      }
    },
    setDeliveryAddress(obj) {
      this.delivery.customer_code = obj.code
      this.delivery.delivery_address = obj.address
      console.log(obj)
    },
    countExtraProd(obj) {
      if (Number(obj.qty_delivered)>Number(obj.qty_order)) return obj.qty_delivered-obj.qty_order
      else return 0
    },
    addVariant() {
      this.delivery.details.push({
        add: true,
        qty_order:0
      })
      let objDiv = document.getElementById("variantTbl").querySelector('.v-data-table__wrapper');
      setTimeout(()=> {
        objDiv.scrollTop = objDiv.scrollHeight
      }, 300)
    },
    removeVariant(idx) {
      this.delivery.details.splice(idx, 1)
      console.log(this.delivery.details, idx)
    },
    beforeSet($ev) {
      console.log($ev)
    },
    setAddedVariant(selected, item, idx) {
      const found = this.delivery.details.find(element => {
        return element.variant_code == selected.code
      });
      if (found) {
        this.removeVariant(idx)
        return this.dialogWarning = true
      }
      item.qty_stock = selected.qty_stock
      item.variant_code = selected.code
      item.size = selected.size
      console.log(this.delivery.details)
    },
    onClear() {
      this.delivery.details = []
    },
    async onFileChange(e) {
      if (e==null) { // on clear text
        console.log('cleared')
        return this.onClear()
      }
      if (e.name.substring(0, 10)!=this.delivery.delivery_date) {
        return this.$store.commit('setSnackbar', {saveError: true, errorMessage: "Nama file tidak sesuai dengan Tanggal Delivery!"})
      }
      this.onClear();
      let input = await this.getCsvFile(e)
      const importedItemsTemp = this.csvToJson(input)
      const importedItems = importedItemsTemp.filter(el => {
        return (el.KODE_PRODUK !== null && el.QTY != 0)
      })
      importedItems.forEach(element => {
        console.log(element)
        let variant = this.variants.find(el => el.code === element.KODE_PRODUK)
        this.delivery.details.push({
          variant_code: variant.code,
          size: variant.size,
          name: variant.name,
          qty_order: 0,
          qty_stock: variant.qty_stock,
          qty_delivered: element.QTY,
        })
      });
    },
    getCsvFile(file) {
      return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.readAsText(file);
          reader.onload = function () {
              resolve(reader.result);
          };
          reader.onerror = function (error) {
              reject('Error: ', error);
          };
      })      
    },
    csvToJson (text, headers, quoteChar = '"', delimiter = ',') {
      const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs');

      const match = line => [...line.matchAll(regex)]
        .map(m => m[2])  // we only want the second capture group
        .slice(0, -1);   // cut off blank match at the end

      const lines = text.split('\n');
      const heads = headers ?? match(lines.shift());
      console.log(lines)

      let filteredLines = lines.filter(function (el) {
        return el != '';
      });

      console.log(filteredLines)

      return filteredLines.map(line => {
          return match(line).reduce((acc, cur, i) => {
            // Attempt to parse as a number; replace blank matches with `null`
            const val = cur.length <= 0 ? null : Number(cur) || cur;
            const key = heads[i] ?? `extra_${i}`;
            return { ...acc, [key]: val };
          }, {});
      });
    }    
  }
}
</script>
<style>
  #orderform .col {
    padding: 5px!important;
  }
</style>

<style scoped>
    .centered-input >>> input {
      text-align: center
    }
</style>