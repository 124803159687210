<template>
    <div v-if="dialog">
        <!-- <v-dialog v-model="dialog" scrollable max-width="90%" :transition="false">
          <v-card> -->

        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="PrintResep"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasDownloaded="hasDownloaded($event)"
            ref="html2Pdf"
            :html-to-pdf-options="htmlToPdfOptions"
        >
            <section slot="pdf-content">
                <div style="width:650px; text-align:center; font-size:16px; font-weight:bold">
                    DELIVERY ORDER
                </div>
                <table class="noborder" style="margin-top:25px"> 
                    <tr class="noborder">
                        <td class="noborder">Nomor</td>
                        <td class="noborder">: {{delivery.do_no}}</td>
                    </tr>
                    <tr class="noborder">
                        <td class="noborder">Tanggal</td>
                        <td class="noborder">: {{delivery.delivery_date | moment}}</td>
                    </tr>
                    <tr class="noborder">
                        <td class="noborder">Customer</td>
                        <td class="noborder">: {{delivery.customer_name}}</td>
                    </tr>
                    <tr class="noborder">
                        <td class="noborder">Alamat</td>
                        <td class="noborder">: {{delivery.delivery_address}}</td>
                    </tr>
                </table>
                <table style="width:600px;margin-top: 20px;" class="pdf-item" id="prodlist">
                    <tr>
                        <th>Nama Variant</th>
                        <th>Volume</th>
                        <th>Quantity</th>
                    </tr>
                    <tr v-for="(dlv, idx) in delivery.details" :key="idx">
                        <td>{{dlv.name}}</td>
                        <td>{{dlv.size}} ml</td>
                        <td>{{dlv.qty_delivered}}</td>
                    </tr>
                </table>

                <table class="noborder" style="margin-left:30px; margin-top:30px">
                    <tr class="noborder">
                        <td class="noborder" style="width: 250px; text-align:center">
                            Staff IT<br><br><br><br><br><br><br><br><br>
                            ( _______________________________ )
                        </td>
                        <td class="noborder" style="width:50px">&nbsp;</td>
                        <td class="noborder" style="width: 250px; text-align:center;">
                            Pengirim<br><br><br><br><br><br><br><br><br>
                            ( _______________________________ )
                        </td>
                    </tr>
                </table>                
            </section>
        </vue-html2pdf>                
          <!-- </v-card>
        </v-dialog> -->
        <v-overlay :value="onLoad" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        

    </div>
</template>

<script>
import moment from 'moment'
import VueHtml2pdf from 'vue-html2pdf'

export default {
    components: { VueHtml2pdf },

    data: () => ({
      dialog: false,
      onLoad: false,
      delivery: {},
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      },
      htmlToPdfOptions() {
        return {
            margin: 20
        }
      }
    },

    watch: {
    },

    filters: {
        moment: function (date) {
            return moment(date).format('D MMM YYYY');
        }
    },

    methods: {
        async openDialog(do_no) {
            this.dialog = true
            this.onLoad = true
            this.delivery = await this.$store.dispatch('deliveryorder/getObj', do_no)
            console.log(this.delivery)
            this.print()
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        hasDownloaded(ev) {
            this.onLoad = false
            console.log(ev)
        },

        async print() {
            // await this.$htmlToPaper('printMe');
            // if (!(await this.validateControlValue())) return;
            this.$refs.html2Pdf.generatePdf()
        }
    }    
}
</script>

<style scoped>
/* #prodlist table, #prodlist th, #prodlist td { */
table, th, td {
  border: 1px solid grey;
  border-collapse: collapse;
}
#prodlist th, td {
  padding: 10px;
  font-size: 14px;
}
.box {
    border:1px solid grey;
    height:30px;
    width:100px;    
}
.noborder {
    border-width: 0px;
    padding: 2px;
}
</style>